import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap';
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import Img2 from '../images/svg/startups2.svg'
import Img3 from '../images/svg/startups3.svg'
import BlogForm from './Company/Blog/BlogForm'


const Startups = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "startup_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 

      }
    `}
            render={data => {
                // const pathName = props.location.pathName
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/startups/">
                        <section className="banner_min">
                            <div className="banner_section">
                                <Img
                                    fluid={imageData}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div className="banner_blur2">
                                <div className="first_banner">
                                    <Container>
                                        <Row>
                                            <Col sm="12" md={{ size: 7, offset: 6 }}>
                                                <Fade right>
                                                    <div>
                                                        <h1>Try Trident <br /> Experience Tenacity</h1>
                                                        {/* <h1></h1> */}
                                                    </div>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="startup_bg">
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        {/* <Col md="2">
                                        <div className="section_img section_img2">
                                            <img src={Img1} alt="img" />
                                        </div>
                                    </Col> */}
                                        <Col md="12">
                                            <div className="startup_section_content">
                                                <div className="startup_sectio_text_center">
                                                    <div className="text_center">
                                                        <h3>Are you finding it hard to hire engineers and scientists to join your business?</h3>
                                                        <p>Trident tries harder, reaches out smarter, and pulls out all stops to ensure your success.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section>
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        <Col md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
                                            <div className="r_text_center">
                                                <h3>Beyond Job Reqs</h3>
                                                <p>Let’s face it. Most job requirements were written yesterday to hire for tomorrow. And in that time, technology has changed, your project has evolved, and your needs are now different. Using a dated requirement only sets up the hiring process for failure. We approach start up recruiting differently. By partnering with your firm, we get a deeper insight into your project, product, or as-a-Service platform, and build familiarity with your evolving technology stacks. These insights help us hone our search for the right talent to accelerate your success.</p>
                                            </div>
                                        </Col>
                                        <Col md={{ size: 2, order: 2 }} xs={{ order: 1 }}>
                                            <div className="section_img">
                                                <img src={Img2} alt="img" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="startup_bg">
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        <Col md="2">
                                            <div className="section_img section_img2">
                                                <img src={Img3} alt="img" />
                                            </div>
                                        </Col>
                                        <Col md="10">
                                            <div className="startup_section_text">
                                                <h3>Beyond Job Boards</h3>
                                                <p>If we all fish in the same pond, how do you land a shark? Most desirable candidates are already in conversations or in the midst of contract negotiations. Trident is adept at building long-term relationships with the talent we work with. In many cases, we don’t even hit the job boards and hot lists, but work within our trusted network to broadcast our needs, seek discreet introductions, and tap sleeper candidates. As they say, the ideal candidate for you out there doesn’t even know she’s being recruited.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="divider" />
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>List your niche requirements with us</h1>
                                        <div className="footer_button">
                                            <a onClick={toggle} href>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="List your niche requirements with us" />
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                        {/* <BlogForm refItem={() => { }} title="List your niche requirements with us" redirectTitle="Startups" pathname={props.location.pathname} /> */}

                    </Layout >
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Startups)`
width: 100%;
background-position: bottom center;
background-repeat: repeat-y;
background-size: cover;
margin: 0;
background-color: inherit !important;

`

export default StyledBackgroundSection